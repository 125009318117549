import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filming',
  templateUrl: './filming.component.html',
  styles: [
  ]
})
export class FilmingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
