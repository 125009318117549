
<div class="app-container">
    <div class="site filming">
        <div class="pagecontainer">
            <div class="page page-fullwidth">
                <div class="wrapper">
    
                    <div class="wrapper relative iframecontainer">
                        <div class="overlay"></div>
                        <div class="imageplaceholder"></div>
                        <div id="main-iframe-container"><iframe id="iframe" src="https://player.vimeo.com/video/760807327?h=88464ee24e&amp;badge=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=1&amp;autoplay=0&amp;loop=0&amp;muted=1&amp;controls=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="width:100%;height:90%;" title="" data-ready="true"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                      </div>
    
                </div>
            </div>
        </div>
    </div>
    
    <div class="onpage maincontent">
        <div class="wrapper maintext">
            <div class="row">
                <div class="col">
                    <h1 class="maintitle white">filming</h1>
                    <p class="mainp white">
                        If a story is worth filming, it must be exquisite.
                    </p>
                </div>
            </div>    
            <div class="row group-btns01">
                <div class="col-md-auto buton buton01">
                    <div class="btn btn00 info">
                        <div class="linksplitscrolla item white opacity0" ><a class="lateanimate"><div class="splita">DIRECTORS </div></a></div>
                    </div>
                </div>
                <div class="col-md-auto buton buton02">
                    <div onclick="reel('https://player.vimeo.com/video/764256392?h=88464ee24e')" class="btn btn01 ">
                        <div class="linksplitscrolla item white" ><a class="lateanimate"><div class="splita">PLAY VIDEO</div></a></div>
                    </div>
                    
                </div>
                <div class="col-md-auto">
                    <div id="circleProgress" class="circleP">
                        <div class="circlecontainer relative">
                            <div class="wrapper wrapper01">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                                    <circle cx="17" cy="17" r="15.5" class="countdown__background"/>
                                    <circle cx="17" cy="17" r="15.5" class="countdown__load"/>
                                    <circle cx="17" cy="17" r="15.5" class="countdown__progress js-countdown__progress"/>
                                </svg>
                            </div>
                        </div>
                        <div class="textP">
                            <div class="wrapper">
                                <div class="txt text01">Loading...</div>
                                <div class="txt text02">Close video</div>    
                            </div>
                        </div>    
    
                      </div>      
        
                </div>
            </div>
        </div>
    </div>


    <div id="modal" class="modal modal01 module">
        <div class="wrapper">
            <div class="titles row padd-b-1">
                <div class="col">
                    <h2>directors</h2>
                </div>
            </div>
            <div class="separador row"></div>
            <div class="directors row rows01">
                <div class="wrapper">
                    <a href="http://vimeo.com/jesusplaza" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Jesús Plaza</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="http://vimeo.com/scapuz" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Santi Capuz</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="http://vimeo.com/luisalvarezdirector" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Luis Álvarez</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="https://vimeo.com/catxo" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Catxo López</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="https://vimeo.com/marcjuve" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Marc Juvé</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="https://vimeo.com/sergigarnica" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Sergi Garnica</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Vimeo</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Vimeo</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                    <a href="https://alexesteve.com/reel" target="_blank">
                        <div class="director overVideoReel relative">
                            <div class="wrapper row verticalalign">
                                <div class="col">
                                    <p class="dir">Alex Esteve</p>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrapper vimeoGroup">
                                        <div class="txtcontainer relative">
                                            <div class="overVideoReel-txt1 overVideoReel-txt">Website</div> 
                                            <div class="overVideoReel-txt2 overVideoReel-txt">Website</div> 
                                        </div><div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z" data-name="5-Arrow Up"/></svg></div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    
    
    
</div>
