import { Component, OnInit } from '@angular/core';

declare var homeVideoLoop:any

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    new homeVideoLoop();
  }

}
