import { Component, OnInit } from '@angular/core';

declare var postProductionVideos:any

@Component({
  selector: 'app-postproduction',
  templateUrl: './postproduction.component.html',
  styles: [
  ]
})
export class PostproductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    new postProductionVideos()
  }

}
