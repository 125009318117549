import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { HomeComponent } from './home/home.component';
import { PostproductionComponent } from './postproduction/postproduction.component';
import { FilmingComponent } from './filming/filming.component';
import { ExcellenceComponent } from './excellence/excellence.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './cookies/cookies.component';

@Injectable({
  providedIn: 'root'
})

export class ResolverService implements Resolve<Observable<string>> {

  constructor() { }

  resolve() {
    return of('!').pipe(delay(920));
  }
}


export const routes: Routes = [
  {
    path: 'post-production',
    component: PostproductionComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: 'filming',
    component: FilmingComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: 'excellence',
    component: ExcellenceComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: 'contact',
    component: ContactComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: 'privacy-policy',
    component: PrivacyComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: 'cookies-policy',
    component: CookiesComponent,
    resolve: [ResolverService],
    data: { animation: 'postproduction' }
  },
  {
    path: '',
    component: HomeComponent,
    resolve: [ResolverService],
    data: { animation: 'HomePage' }
  },
  {
    path: '**',
    component: HomeComponent,
    resolve: [ResolverService],
    data: { animation: 'HomePage' }
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: "top",
  }),
    BrowserAnimationsModule,
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
