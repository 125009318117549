<div class="app-container">
    <div class="site">
        <div class="pagecontainer">
            <div class="page page-fullwidth">
                <div class="wrapper">

                    <div class="wrapper relative iframecontainer">
                        <div class="overlay"></div>
                        <div class="imageplaceholder"></div>
                        <div id="main-iframe-container"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="onpage maincontent contact">
        <div class="wrapper maintext">
            <div class="row">
                <div class="col">
                    <h1 class="maintitle white">get in touch</h1>
                    <p class="mainp white">
                    </p>
                </div>
            </div>
            <div class="row leads">
                <div class="wrapper">
                    <div class="row flick">
                        <div class="col">
                            <div class="wrapper lead appearOpacity appearOpacity01">
                                <a href="mailto:mauri.gonzalez@iberian.media">
                                    <div class="row verticalalign butonLead overVideoReel02">
                                        <div class="col-md-auto col-img">
                                            <div class="image">
                                                <img class="fluid" src="assets/images/Mauri-min.jpg">
                                            </div>
                                        </div>
                                        <div class="col col-txt">
                                            <div class="wrapper">
                                                <h2 class="font2">Mauri González</h2>
                                                <p>Post Production Lead</p>
                                                <div class="email row verticalalign">
                                                    <div class="text col-md-auto relative">
                                                        <div class="overVideoReel-txt1 overVideoReel-txt">
                                                            mauri.gonzalez@iberian.media</div>
                                                        <div class="overVideoReel-txt2 overVideoReel-txt">
                                                            mauri.gonzalez@iberian.media</div>
                                                    </div>
                                                    <div class="circleArrow relative col-md-auto"><svg
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                            <path
                                                                d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                                data-name="5-Arrow Up" />
                                                        </svg></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col">
                            <div class="wrapper lead appearOpacity appearOpacity02">
                                <a href="mailto:celia.cantero@iberian.media">
                                    <div class="row verticalalign butonLead overVideoReel02">
                                        <div class="col-md-auto col-img">
                                            <div class="image">
                                                <img class="fluid" src="assets/images/Celia-min.jpg">
                                            </div>
                                        </div>
                                        <div class="col col-txt">
                                            <div class="wrapper">
                                                <h2 class="font2">Celia Cantero</h2>
                                                <p>Filming Lead</p>
                                                <div class="email row verticalalign">
                                                    <div class="text col-md-auto relative">
                                                        <div class="overVideoReel-txt1 overVideoReel-txt">
                                                            celia.cantero@iberian.media</div>
                                                        <div class="overVideoReel-txt2 overVideoReel-txt">
                                                            celia.cantero@iberian.media</div>
                                                    </div>
                                                    <div class="circleArrow relative col-md-auto"><svg
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                            <path
                                                                d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                                data-name="5-Arrow Up" />
                                                        </svg></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row contacts padd-t-3 padd-m-t-1">
                <div class="row rows02">
                    <h2 class="appearChars01">Our offices</h2>
                </div>
                <div class="directors row rows01 appearOpacity appearOpacity03">
                    <div class="wrapper flick02">
                        <a href="https://goo.gl/maps/HvZ5agsqSMZnS7Ed9" target="_blank">
                            <div class="director overVideoReel relative">
                                <div class="wrapper row verticalalign">
                                    <div class="col left">
                                        <div class="row verticalalign">
                                            <div class="col-2 col-ciu">
                                                <p class="dir">Madrid</p>
                                            </div>
                                            <div class="col col-dir">Calle Ramón de Aguinaga 18, plantas 2 y 4. 28008
                                            </div>
                                            <div class="col-3 col-tel">+34 911 26 78 55</div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto right col-link">
                                        <div class="wrapper vimeoGroup">
                                            <div class="txtcontainer relative">
                                                <div class="overVideoReel-txt1 overVideoReel-txt">Map</div>
                                                <div class="overVideoReel-txt2 overVideoReel-txt">Map</div>
                                            </div>
                                            <div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 32 32">
                                                    <path
                                                        d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                        data-name="5-Arrow Up" />
                                                </svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://goo.gl/maps/aCyzCQB4bUWxDaSY7" target="_blank">
                            <div class="director overVideoReel relative">
                                <div class="wrapper row verticalalign">
                                    <div class="col left">
                                        <div class="row verticalalign">
                                            <div class="col-2 col-ciu">
                                                <p class="dir">Buenos Aires</p>
                                            </div>
                                            <div class="col col-dir">Thames 2078, Palermo, CABA, C1425</div>
                                            <div class="col-3 col-tel">+54 9 11 5040 6073</div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto right col-link">
                                        <div class="wrapper vimeoGroup">
                                            <div class="txtcontainer relative">
                                                <div class="overVideoReel-txt1 overVideoReel-txt">Map</div>
                                                <div class="overVideoReel-txt2 overVideoReel-txt">Map</div>
                                            </div>
                                            <div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 32 32">
                                                    <path
                                                        d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                        data-name="5-Arrow Up" />
                                                </svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://goo.gl/maps/SmkqYuytxqLEMQRc6" target="_blank">
                            <div class="director overVideoReel relative">
                                <div class="wrapper row verticalalign">
                                    <div class="col left">
                                        <div class="row verticalalign">
                                            <div class="col-2 col-ciu">
                                                <p class="dir">Lisboa</p>
                                            </div>
                                            <div class="col col-dir">Av. Gen. Norton de Matos 25 A. 1495-151 Algés</div>
                                            <div class="col-3 col-tel">+351 919 331 831</div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto right col-link">
                                        <div class="wrapper vimeoGroup">
                                            <div class="txtcontainer relative">
                                                <div class="overVideoReel-txt1 overVideoReel-txt">Map</div>
                                                <div class="overVideoReel-txt2 overVideoReel-txt">Map</div>
                                            </div>
                                            <div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 32 32">
                                                    <path
                                                        d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                        data-name="5-Arrow Up" />
                                                </svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://goo.gl/maps/pbcAW629WyKrxYik8" target="_blank">
                            <div class="director overVideoReel relative">
                                <div class="wrapper row verticalalign">
                                    <div class="col left">
                                        <div class="row verticalalign">
                                            <div class="col-2 col-ciu">
                                                <p class="dir">Barcelona</p>
                                            </div>
                                            <div class="col col-dir">Carrer Tallers 22, 3-3 08001</div>
                                            <div class="col-3 col-tel">+34 937 68 73 43</div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto right col-link">
                                        <div class="wrapper vimeoGroup">
                                            <div class="txtcontainer relative">
                                                <div class="overVideoReel-txt1 overVideoReel-txt">Map</div>
                                                <div class="overVideoReel-txt2 overVideoReel-txt">Map</div>
                                            </div>
                                            <div class="circleArrow relative"><svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 32 32">
                                                    <path
                                                        d="M31 0H15v2h13.59L.29 30.29 1.7 31.7 30 3.41V16h2V1a1 1 0 0 0-1-1z"
                                                        data-name="5-Arrow Up" />
                                                </svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
            <div class="row group-btns01">

                <div class="col-md-auto">
                    <div id="circleProgress" class="circleP">
                        <div class="circlecontainer relative">
                            <div class="wrapper wrapper01">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                                    <circle cx="17" cy="17" r="15.5" class="countdown__background" />
                                    <circle cx="17" cy="17" r="15.5" class="countdown__load" />
                                    <circle cx="17" cy="17" r="15.5"
                                        class="countdown__progress js-countdown__progress" />
                                </svg>
                            </div>
                        </div>
                        <div class="textP">
                            <div class="wrapper">
                                <div class="txt text01">Loading...</div>
                                <div class="txt text02">Close video</div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="footer appearOpacity appearOpacity04">
            <div class="wrapper">
                <div class="row footer1">
                    <div class="col-md-auto followus">Follow us</div>
                    <a class="col-md-auto overVideoReel" href="https://www.linkedin.com/company/iberian-media/">
                        <div class="rs lk relative ">
                            <div class="overVideoReel-txt1 overVideoReel-txt">LinkedIn</div>
                            <div class="overVideoReel-txt2 overVideoReel-txt">LinkedIn</div>
                        </div>
                    </a>
                    <a class="col-md-auto overVideoReel" href="https://www.instagram.com/iberian.media/">
                        <div class="rs lk relative ">
                            <div class="overVideoReel-txt1 overVideoReel-txt">Instagram</div>
                            <div class="overVideoReel-txt2 overVideoReel-txt">Instagram</div>
                        </div>
                    </a>
                    <a class="col-md-auto overVideoReel" href="https://www.youtube.com/@IberianMedia">
                        <div class="rs lk relative ">
                            <div class="overVideoReel-txt1 overVideoReel-txt">Youtube</div>
                            <div class="overVideoReel-txt2 overVideoReel-txt">Youtube</div>
                        </div>
                    </a>
                </div>
                <div class="row footer2 padd-t-3">
                    <div class="col legal">
                        <div class="row">
                            <a class="col-md-auto overVideoReel03" href="assets/pdf/policy.pdf" target="_blank">
                                <div class="rs lk relative ">
                                    <div class="overVideoReel-txt1 overVideoReel-txt">Privacy policy</div>
                                    <div class="overVideoReel-txt2 overVideoReel-txt">Privacy policy</div>
                                </div>
                            </a>
                            <a class="col-md-auto overVideoReel03" href="assets/pdf/policy.pdf" target="_blank">
                                <div class="rs lk relative ">
                                    <div class="overVideoReel-txt1 overVideoReel-txt">Cookies policy</div>
                                    <div class="overVideoReel-txt2 overVideoReel-txt">Cookies policy</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-auto copyright">

                        <div class="wrapper europe">
                            <div class="row">
                                <img class="padd-b-1" src="assets/images/EuropeContact.png">
                                <div class="txt">IBERIAN MEDIA PRODUCTIONS SL within the framework of the ICEX Next
                                    Program, has with the support of ICEX and with the co-financing of the European
                                    FEDER fund. The purpose of this support is to contribute to the international
                                    development of the company and its environment.
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>

    <div id="modal" class="modal01"></div>



</div>